<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Reporte: Pagos semanal" cardTextFooter>
      <template v-slot:buttons>
        <v-col cols="2">
          <v-select v-model="forms.filters.tipo" :items="catalogues.tipos" item-text="text" item-value="value"
          label="Tipo de vista" class="mx-2" :disabled="loading" required @change="setType()"></v-select>
        </v-col>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :disabled="!forms.filters.tipo" :loading="loading" />
      </template>
      <template v-slot:search>
        <Basic-Btn text="Descargar" color="primary" icon="mdi-download" @click="download()" :loading="loading"  />
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="20" item-key="id" class="elevation-1" dense>
        <template template v-slot:[`item.monto_total_autorizado`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_total_autorizado) }}
        </template>
        <template template v-slot:[`item.monto_total_pendiente`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_total_pendiente) }}
        </template>
        <template template v-slot:[`item.monto_pago_semanal`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_pago_semanal) }}
        </template>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components"
import services from "@/utils/services"
import moment from 'moment-timezone'
import downloadjs from 'downloadjs'
import * as Papa from 'papaparse'

export default {
  name: "weekly-payment",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
  },
  data: () => ({
    alert: {
      active: false,
      color: 'error',
      msg: '',
    },
    loading: false,
    catalogues: {
      tipos: [
        { text: 'Cliente', value: 'cliente' },
        { text: 'Grupo', value: 'grupo' },
      ],
      usuarios: [],
    },
    headers: [],
    headersClient: [
      { text: "GRUPO", align: "left", sortable: true, value: "grupo" },
      { text: "ID SOLICITUD", align: "left", sortable: true, value: "id_solicitud" },
      { text: "NOMBRE DEL CLIENTE", align: "left", sortable: true, value: "nombre_cliente" },
      { text: "SEMANA", align: "left", sortable: true, value: "semana" },
      { text: "MONTO TOTAL AUTORIZADO", align: "left", sortable: true, value: "monto_total_autorizado" },
      { text: "MONTO TOTAL PENDIENTE", align: "left", sortable: true, value: "monto_total_pendiente" },
      { text: "MONTO DE PAGO SEMANAL", align: "left", sortable: true, value: "monto_pago_semanal" },
      { text: "FECHA FIN DE PAGO", align: "left", sortable: true, value: "fecha_fin_pago" },
      { text: "MONTO PAGADO PENDIENTE", align: "left", sortable: true, value: "monto_pagado_pendiente" },
      { text: "MONTO PAGADO AUTORIZADO", align: "left", sortable: true, value: "monto_pagado_autorizado" },
    ],
    headersGroup: [
      { text: "GRUPO", align: "left", sortable: true, value: "grupo" },
      { text: "MONTO TOTAL AUTORIZADO", align: "left", sortable: true, value: "monto_total_autorizado" },
      { text: "MONTO TOTAL PENDIENTE", align: "left", sortable: true, value: "monto_total_pendiente" },
      { text: "MONTO DE PAGO SEMANAL", align: "left", sortable: true, value: "monto_pago_semanal" },
      { text: "MONTO PAGADO PENDIENTE", align: "left", sortable: true, value: "monto_pagado_pendiente" },
      { text: "MONTO PAGADO AUTORIZADO", align: "left", sortable: true, value: "monto_pagado_autorizado" },
    ],
    items: [],
    forms: {
      filters: {
        tipo: null
      }
    }
  }),
  mounted() {
    this.getCatalogues()
  },
  methods: {
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    },
    async getCatalogues() {
      const user = await this.axios.get(services.routes.user)
      if (user.data.data) {
        this.catalogues.usuarios = user.data.data
        for (let usuario of this.catalogues.usuarios) {
          const nombre = _.get(usuario, 'detalles_usuarios[0].nombre', '').trim();
          const apellido_paterno = _.get(usuario, 'detalles_usuarios[0].apellido_paterno', '').trim();
          const apellido_materno = _.get(usuario, 'detalles_usuarios[0].apellido_materno', '').trim();
          const nombre_completo = `${nombre} ${apellido_paterno} ${apellido_materno}`.trim().toUpperCase() + ` (${usuario.email})`;
          usuario.nombre_completo = nombre_completo
        }
      }
    },
    setType() {
      if (this.forms.filters.tipo === 'cliente') {
        this.headers = this.headersClient
      }
      else if (this.forms.filters.tipo === 'grupo') {
        this.headers = this.headersGroup
      }
      this.refresh()
    },
    refresh() {
      if (this.forms.filters.tipo) {
        this.loading = true
        this.items = []
        let params = {
          tipo: this.forms.filters.tipo
        }
        this.axios.get(services.routes.weeklyPaymentReport, { params })
          .then((response) => {
            this.items = response.data.data
          })
          .catch((error) => {
            this.alert.active = true
            this.alert.color = 'error'
            this.alert.msg = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.alert.active = true
        this.alert.color = 'error'
        this.alert.msg = 'Debe seleccionar un tipo de vista disponible'
      }
    },
    download() {
      if (this.items && this.items.length) {
        let data = []
        for (let i = 0; i < this.items.length; i++) {
          let row = {}
          for (let j = 0; j < this.headers.length; j++) {
            row[this.headers[j].text] = this.items[i][this.headers[j].value]
          }
          data.push(row)
        }
        const date = moment.tz('America/Monterrey').format('YYYYMMDD')
        const csv = Papa.unparse(data);
        downloadjs(csv, 'Reporte_Pagos_Semanal_' + date + '.csv', 'text/csv')
      }
      else {
        this.alert.active = true
        this.alert.color = 'error'
        this.alert.msg = 'No existe información disponible'
      }
    }
  },
};
</script>

<style scoped>
</style>
